<!--footer start-->
<footer class="saas1 footer2">
    <div class="container">
        <div class="row">
            <div class="col-md-3">
                <div class="logo-sec">
                    <div class="footer-title mobile-title">
                        <h3 class="text-white">{{'footer.etqan' | translate}}</h3>
                    </div>
                    <div class="footer-contant">
                        <img src="assets/images/logo/log.png" alt="" class="img-fluid footer-logo">
                        <div class="footer-para">
                            <h6 class="text-white para-address">{{'footer.country' | translate}}</h6>
                            <h6 class="text-white para-address">{{'footer.city' | translate}}</h6>
                            <h6 class="text-white para-address">{{'footer.street' | translate}}</h6>
                        </div>
                        <ul class="d-d-flex footer-social social">
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                            </li>
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                            </li>
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-google" aria-hidden="true"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
          
            <div class="col-md-9">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">{{'footer.visit' | translate}}</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">{{'footer.map' | translate}}</h5>
                    <div class="imageContainter">
                        <img src="assets/images/map.png">
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!--footer end-->
<app-tap-to-top></app-tap-to-top>
