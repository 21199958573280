import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-enterprice-sass-feature',
  templateUrl: './enterprice-sass-feature.component.html',
  styleUrls: ['./enterprice-sass-feature.component.scss']
})
export class EnterpriceSassFeatureComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  features= [
    {
      title:'about.service1',
      subTitle: 'Design',
      description: 'about.service1_discription',
      img: 'assets/images/saas1/feature/1.png',
    },
    {
      title:'about.service2',
      subTitle: 'Installation',
      description: 'about.service2_discription',
      img: 'assets/images/saas1/feature/2.png',
    },
    {
      title:'about.service3',
      subTitle: 'Maintenance',
      description: 'about.service3_discription',
      img: 'assets/images/saas1/feature/3.png',
    },
    {
      title:'about.service4',
      subTitle: 'Prices',
      description: 'about.service4_discription',
      img: 'assets/images/saas1/feature/4.png',
    },
    {
      title:'about.service5',
      subTitle: 'Quality',
      description: 'about.service5_discription',
      img: 'assets/images/saas1/feature/5.png',
    },
    {
      title:'about.service6',
      subTitle: 'Team',
      description: 'about.service6_discription',
      img: 'assets/images/saas1/feature/6.png',
    }
  ]
}
