<!-- price table -->
<section id="plan" class="saas1 pricing"  [ngStyle]="{'background-image': 'url(assets/images/saas1/banner-2.jpg)'}">
    <div class="container">
        <div class="col-md-12 text-center">
            <div class="title">
                <img src="assets/images/saas1/title-logo.png" alt="title-logo" class="img-fluid">
                <div class="main-title">
                    <h2 class="text-white" [innerHTML]="'machines.header' | translate">
                    </h2>
                </div>
                <hr>
                <div class="sub-title">
                    <p class="p-padding text-white">{{'machines.subtitle' | translate}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div id="monthly" class="wrapper-full">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                        <owl-carousel-o [options]="priceCarouselOptions" class="pricing-slider plan-box">
                        <ng-container>
                        <ng-template carouselSlide *ngFor="let design of designs">
                            <div class="price-box"  [ngStyle]="{'background-image': 'url(' + design.img + ')'}">
                                <h3 class="price-heading">{{design.heading | translate}}</h3>
                                <img src="assets/images/saas1/plan-box.png" alt="pan-line">
                        </div>
                        </ng-template>
                        </ng-container>
                        </owl-carousel-o>
                    </div>
            </div>
        </div>
    </div>
</section>
<!-- end price table -->
