import { Component, Renderer2, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { CustomizerService } from './shared/service/customizer.service';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ETQAN';
  public layoutType: string = 'dark'
  public url: any;
  currenttype: string;

  constructor(private translate: TranslateService, public customize: CustomizerService, private router: Router, private _renderer2: Renderer2,  @Inject(DOCUMENT) private _document: Document) { 
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    });
    
    this.customize.data.settings.layout_type = localStorage.getItem("layoutType") || 'ltr'
    this.customizeLayoutType(this.customize.data.settings.layout_type)

    if ( this.currenttype === "rtl" ){
      translate.setDefaultLang('ar');
      translate.use('ar');

    }else{
      translate.setDefaultLang('en');
      translate.use('en');
    }
  }

   
  public ngOnInit() {
    let script = this._renderer2.createElement('script');
    script.src  = `/assets/tilt.js`;   
    this._renderer2.appendChild(this._document.body, script);
    // this.customizeLayoutType()
  }
  customizeLayoutVersion(val) {
    this.customize.setLayoutVersion(val)
 

  }

  customizeLayoutType(val) {
    this.customize.setLayoutType(val)
    this.layoutType = val
    this.currenttype = val
    this.layoutType = val
    if (val === 'rtl'){
      this.translate.use('ar');
    }else{
      this.translate.use('en');

    }
  }
}
