import { Component, OnInit } from '@angular/core';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-enterprice-sass-testimonial',
  templateUrl: './enterprice-sass-testimonial.component.html',
  styleUrls: ['./enterprice-sass-testimonial.component.scss']
})
export class EnterpriceSassTestimonialComponent implements OnInit {

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
  }

  testimonialcarousel = [
    { 
      quote:"assets/images/saas1/testimonail-dot.png",
      review:"We picked ETQAN to do our Kitchen Remodel because they provide the design skills and material quality. Our budget was managed and the house looks fantastic. It's always a pleasure to deal with a true professional.",
      userImg:"assets/images/saas1/testi-profile.png",
      userName:"Mohammed Al Houch",
      position:"Resturant Owner"    
    },  
    { 
      quote:"assets/images/saas1/testimonail-dot.png",
      review:"We picked ETQAN to do our Kitchen Remodel because they provide the design skills and material quality. Our budget was managed and the house looks fantastic. It's always a pleasure to deal with a true professional.",
      userImg:"assets/images/saas1/testi-profile.png",
      userName:"Ahmed Al Anazi",
      position:"Home Owner"     
    },
    { 
      quote:"assets/images/saas1/testimonail-dot.png",
      review:"We picked ETQAN to do our Kitchen Remodel because they provide the design skills and material quality. Our budget was managed and the house looks fantastic. It's always a pleasure to deal with a true professional.",
      userImg:"assets/images/saas1/testi-profile.png",
      userName:"Abdullah Al Rabiah",
      position:"Resturant Owner"    
    }
  ]
  
  testimonialcarouselOptions= {
    items: 1,
    loop: true,
    margin: 10,
    nav: false
  }

  openVerticallyCentered(content) {
    this.modalService.open(content, { centered: true, size: 'lg' });
  }

}
