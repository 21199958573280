<!-- subscribe section -->
<section id="contact" class="saas1 subscribe" [ngStyle]="{'background-image': 'url(assets/images/saas1/banner-2.jpg)'}">
    <div class="container">
        <div class="row">
            <div class="col-lg-7">
                <div class="media">
                    <img class="" src="assets/images/saas1/subscribe-icon.png" alt="profile">
                    <div class="media-body">
                        <h3 class="mt-0 text-white">{{'quatation.header' | translate}}</h3>
                        <h6 class="text-white"> {{'quatation.text' | translate}}
                        </h6>
                    </div>
                </div>
            </div>
            <div class="col-lg-5">
                <div class="subscribe-input">
                    <form>
                        <input id="name" type="text" [placeholder]="'quatation.placeholder.name' | translate" required>
                        <input id="phone" type="number" [placeholder]="'quatation.placeholder.phone' | translate" required>
                        <input id="useremail" type="email" [placeholder]="'quatation.placeholder.email' | translate" required>
                        <input id="Project" type="text" [placeholder]="'quatation.placeholder.summery' | translate" required>
                        <input id="submit" type="submit" [value]="'quatation.placeholder.submit' | translate">
                    </form>
                    <!-- <div class="text text-center d-flex">
                        <h6 class="text-white">Totally Free</h6><h6 class="text-white">Fast Response</h6><h6
                            class="text-white">100% satisfaction</h6>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- end subscribe section -->
