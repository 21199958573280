import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SlidesOutputData, OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-enterprice-sass-build',
  templateUrl: './enterprice-sass-build.component.html',
  styleUrls: ['./enterprice-sass-build.component.scss']
})
export class EnterpriceSassBuildComponent implements OnInit {
  @ViewChild('owlCar') nameInputRef: ElementRef;
  activeSlides: SlidesOutputData;

  constructor() { }

  ngOnInit() {
  }

  buildsync = [
    {
      img: "assets/images/saas1/work2.png"
    },
    {
      img: "assets/images/saas1/work1.png"
    },
    {
      img: "assets/images/saas1/work3.png"
    }
  ]
  navsync = [
    {
      headlineFirstWord:'build.navItems.1.headlineFirstWord',
      headline:'build.navItems.1.headline',
      data: 'build.navItems.1.data',
      offers:[
        'build.navItems.1.offer1',
        'build.navItems.1.offer2',
        'build.navItems.1.offer3',
      ]
    },
    {
      headlineFirstWord:'build.navItems.2.headlineFirstWord',
      headline:'build.navItems.2.headline',
      data: 'build.navItems.2.data',
      offers:[
        'build.navItems.2.offer1',
        'build.navItems.2.offer2',
        'build.navItems.2.offer3',
      ]
    },
    {
      headlineFirstWord:'build.navItems.3.headlineFirstWord',
      headline:'build.navItems.3.headline',
      data: 'build.navItems.3.data',
      offers:[
        'build.navItems.3.offer1',
        'build.navItems.3.offer2',
        'build.navItems.3.offer3',
      ]
    }
  ]
  slidesPerPage = 1;
  sync1 = {
    items: 1,
    slideSpeed: 2000,
    nav: false,
    autoplay: false,
    dots: false,
    loop: true,
    navText: ['<svg width="100%" height="100%" viewBox="0 0 11 20"><path style="fill:none;stroke-width: 1px;stroke: #000;" d="M9.554,1.001l-8.607,8.607l8.607,8.606"/></svg>', '<svg width="100%" height="100%" viewBox="0 0 11 20" version="1.1"><path style="fill:none;stroke-width: 1px;stroke: #000;" d="M1.054,18.214l8.606,-8.606l-8.606,-8.607"/></svg>'],
  }

  sync2 = {
    items: this.slidesPerPage,
    dots: true,
    nav: false,
    smartSpeed: 200,
    slideSpeed: 500,
    slideBy: this.slidesPerPage,
    responsiveRefreshRate: 100
  }

  getData(data: SlidesOutputData) {
    this.activeSlides = data;
  }

  slideTo(data) {
    this.activeSlides = data;
  }
}
