import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-enterprice-sass-price',
  templateUrl: './enterprice-sass-price.component.html',
  styleUrls: ['./enterprice-sass-price.component.scss']
})
export class EnterpriceSassPriceComponent implements OnInit {

  public val:boolean=true;

  constructor() { }

  ngOnInit() {
  }
  
  priceCarouselOptions= {
    items: 3,
    nav: false,
    dots:true,
    autoplay: false,
    center: true,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true,
    responsive: {
        0: {
            items: 1
        },
        576: {
            items: 1
        },
        768: {
            items: 2,
            margin: 20
        },
        992: {
            items: 3
        }
    }
  }

  designs=[
    { 
      heading:"machines.designs.1.heading",
      img: "assets/images/saas1/machines/1.jpg" 
    },
    { 
      heading:"machines.designs.2.heading",
      img: "assets/images/saas1/machines/2.jpg" 
    },
     { 
      heading:"machines.designs.3.heading",
      img: "assets/images/saas1/machines/3.jpg" 
    },
    { 
      heading:"machines.designs.4.heading",
      img: "assets/images/saas1/machines/4.jpg" 
    },
    { 
      heading:"machines.designs.5.heading",
      img: "assets/images/saas1/machines/5.jpg" 
    },
    { 
      heading:"machines.designs.6.heading",
      img: "assets/images/saas1/machines/6.jpg" 
    },
    { 
      heading:"machines.designs.7.heading",
      img: "assets/images/saas1/machines/7.jpg" 
    },
    { 
      heading:"machines.designs.8.heading",
      img: "assets/images/saas1/machines/8.jpg" 
    },
    { 
      heading:"machines.designs.9.heading",
      img: "assets/images/saas1/machines/9.jpg" 
    },
    { 
      heading:"machines.designs.10.heading",
      img: "assets/images/saas1/machines/10.jpg" 
    },
    { 
      heading:"machines.designs.11.heading",
      img: "assets/images/saas1/machines/11.jpg" 
    }
  ]

  monthly(){
    this.val=!this.val;
  }

}
