import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EnterpriceSassComponent } from './layouts/enterprice-sass/enterprice-sass.component'
export const routes: Routes = [
  {
    path: '',
    redirectTo: 'Company-Profile',
    pathMatch: 'full',
  },
  {
    path: 'Company-Profile',
    component: EnterpriceSassComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    // relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
