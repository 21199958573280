import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutsRoutingModule } from './layouts-routing.module';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SwiperModule } from 'swiper/angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CountToModule } from 'angular-count-to';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { NgxPayPalModule } from 'ngx-paypal';
import { Ng5SliderModule } from 'ng5-slider';
import { SharedModule } from '../shared/shared.module'
import { NgxMasonryModule } from 'ngx-masonry';
// Enterprice SASS Layout
import { EnterpriceSassComponent } from './enterprice-sass/enterprice-sass.component';
import { EnterpriceSassNavComponent } from './enterprice-sass/enterprice-sass-nav/enterprice-sass-nav.component';
import { EnterpriceSassHeaderComponent } from './enterprice-sass/enterprice-sass-header/enterprice-sass-header.component';
import { EnterpriceSassFeatureComponent } from './enterprice-sass/enterprice-sass-feature/enterprice-sass-feature.component';
import { EnterpriceSassBuildComponent } from './enterprice-sass/enterprice-sass-build/enterprice-sass-build.component';
import { EnterpriceSassWorkComponent } from './enterprice-sass/enterprice-sass-work/enterprice-sass-work.component';
import { EnterpriceSassPriceComponent } from './enterprice-sass/enterprice-sass-price/enterprice-sass-price.component';
import { EnterpriceSassTestimonialComponent } from './enterprice-sass/enterprice-sass-testimonial/enterprice-sass-testimonial.component';
import { EnterpriceSassSubscribeComponent } from './enterprice-sass/enterprice-sass-subscribe/enterprice-sass-subscribe.component';
import { EnterpriceSassClientComponent } from './enterprice-sass/enterprice-sass-client/enterprice-sass-client.component';
import { EnterpriceSassFooterComponent } from './enterprice-sass/enterprice-sass-footer/enterprice-sass-footer.component';
import { EnterpriceSassCopyrightComponent } from './enterprice-sass/enterprice-sass-copyright/enterprice-sass-copyright.component';



@NgModule({
  declarations: [
    EnterpriceSassComponent, EnterpriceSassNavComponent, EnterpriceSassHeaderComponent, EnterpriceSassFeatureComponent, EnterpriceSassBuildComponent, EnterpriceSassWorkComponent, EnterpriceSassPriceComponent, EnterpriceSassTestimonialComponent, EnterpriceSassSubscribeComponent, EnterpriceSassClientComponent, EnterpriceSassFooterComponent, EnterpriceSassCopyrightComponent],

  imports: [
    CommonModule,
    LayoutsRoutingModule,
    SwiperModule,
    CarouselModule,
    NgbModule,
    SharedModule,
    // CountToModule,
    FormsModule,
    ReactiveFormsModule,
    // NgxPayPalModule,
    // Ng5SliderModule,
    // NgxMasonryModule
  ],
  exports: [],
})

export class LayoutsModule { }
