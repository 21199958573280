import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-enterprice-sass-work',
  templateUrl: './enterprice-sass-work.component.html',
  styleUrls: ['./enterprice-sass-work.component.scss']
})
export class EnterpriceSassWorkComponent implements OnInit {
  public work: any = 'work.navItems.1.title'
  public text: any = 'work.navItems.1.text'
  constructor() { }

  ngOnInit() {
  }

  openWorkSlide(val){
    this.work = val.title
    this.text = val.text
  }

  nav = [
    {
      img:'assets/images/saas1/tab-icon/design.png',
      title:'work.navItems.1.title',
      text:'work.navItems.1.text'

    },
    {
      img:'assets/images/saas1/tab-icon/maintainenace.png',
      title:'work.navItems.2.title',
      text:'work.navItems.2.text'
    },
    {
      img:'assets/images/saas1/tab-icon/kitchen.png',
      title:'work.navItems.3.title',
      text:'work.navItems.3.text'

    },
    {
      img:'assets/images/saas1/tab-icon/satisfaction.png',
      title:'work.navItems.4.title',
      text:'work.navItems.4.text'
    },
    {
      img:'assets/images/saas1/tab-icon/price.png',
      title:'work.navItems.5.title',
      text:'work.navItems.5.text'
    },
  ]

  content=[
    {
      img:'assets/images/saas1/tab/design.png',
      title:'work.navItems.1.title'
    },
    {
      img:'assets/images/saas1/tab/maintainenace.png',
      title:'work.navItems.2.title'
    },
    {
      img:'assets/images/saas1/tab/kitchen.png',
      title:'work.navItems.3.title'
    },
    {
      img:'assets/images/saas1/tab/satisfaction.png',
      title:'work.navItems.4.title'
    },
    {
      img:'assets/images/saas1/tab/price.png',
      title:'work.navItems.5.title'
    }
  ]

}
