<!-- Navbar section Start-->
<header class="saas1 header-fixed loding-header position-absolute  custom-scroll nav-lg">
    <div class="container">
        <div class="row">
            <div class="col">
                <nav>
                    <a href="#" class="d-inline-block m-auto">
                            <img src="assets/images/logo/1.svg" alt="" class="img-c">
                    </a>
                <!-- <app-menu></app-menu> -->
            </nav>
            </div>
        </div>
    </div>
</header>
<!-- Navbar section end-->
