import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-enterprice-sass',
  templateUrl: './enterprice-sass.component.html',
  styleUrls: ['./enterprice-sass.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EnterpriceSassComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private title: Title) { }

  clients1 = [
    { img: "assets/images/saas1/brand/1.png" },
    { img: "assets/images/saas1/brand/2.png" },
    { img: "assets/images/saas1/brand/3.png" },
    { img: "assets/images/saas1/brand/4.png" },
    { img: "assets/images/saas1/brand/5.png" },

    { img: "assets/images/saas1/brand/1.png" },
    { img: "assets/images/saas1/brand/2.png" },
    { img: "assets/images/saas1/brand/3.png" },
    { img: "assets/images/saas1/brand/4.png" },
    { img: "assets/images/saas1/brand/5.png" }

  ]
  clients2 = [
    // { img: "assets/images/saas1/companies/1.png" },
    // { img: "assets/images/saas1/companies/2.png" },
    // { img: "assets/images/saas1/companies/3.png" },
    // { img: "assets/images/saas1/companies/4.png" },
    // { img: "assets/images/saas1/companies/5.png" },
    // { img: "assets/images/saas1/companies/6.png" },
    // { img: "assets/images/saas1/companies/7.png" },
    // { img: "assets/images/saas1/companies/8.png" },
    // { img: "assets/images/saas1/companies/9.png" },
    // { img: "assets/images/saas1/companies/10.png" },
    // { img: "assets/images/saas1/companies/11.png" },
    // { img: "assets/images/saas1/companies/12.png" },
    // { img: "assets/images/saas1/companies/13.png" },
    // { img: "assets/images/saas1/companies/14.png" },
    // { img: "assets/images/saas1/companies/15.png" }


    { img: "assets/images/saas1/companies/68.jpg" },
    { img: "assets/images/saas1/companies/69.jpg" },
    { img: "assets/images/saas1/companies/70.jpg" },
    { img: "assets/images/saas1/companies/71.jpg" },
    { img: "assets/images/saas1/companies/72.png" },
    { img: "assets/images/saas1/companies/73.png" },
    { img: "assets/images/saas1/companies/74.png" },
    { img: "assets/images/saas1/companies/1.png" },
    { img: "assets/images/saas1/companies/2.png" },
    { img: "assets/images/saas1/companies/3.png" },
    { img: "assets/images/saas1/companies/4.png" },
    { img: "assets/images/saas1/companies/5.png" },
    { img: "assets/images/saas1/companies/6.png" },
    { img: "assets/images/saas1/companies/7.png" },
    { img: "assets/images/saas1/companies/8.png" },
    { img: "assets/images/saas1/companies/9.png" },
    { img: "assets/images/saas1/companies/10.png" },
    { img: "assets/images/saas1/companies/11.png" },
    { img: "assets/images/saas1/companies/14.png" },
    { img: "assets/images/saas1/companies/15.png" },
    { img: "assets/images/saas1/companies/16.png" },
    { img: "assets/images/saas1/companies/17.png" },
    { img: "assets/images/saas1/companies/18.png" },
    { img: "assets/images/saas1/companies/19.png" },
    { img: "assets/images/saas1/companies/20.png" },
    { img: "assets/images/saas1/companies/22.png" },
    { img: "assets/images/saas1/companies/23.jpg" },
    { img: "assets/images/saas1/companies/24.jpg" },
    { img: "assets/images/saas1/companies/25.jpg" },
    { img: "assets/images/saas1/companies/26.jpg" },
    { img: "assets/images/saas1/companies/29.png" },
    { img: "assets/images/saas1/companies/30.png" },
    { img: "assets/images/saas1/companies/31.png" },
    { img: "assets/images/saas1/companies/32.jpg" },
    { img: "assets/images/saas1/companies/33.jpg" },
    { img: "assets/images/saas1/companies/34.png" },
    { img: "assets/images/saas1/companies/35.jpg" },
    { img: "assets/images/saas1/companies/36.png" },
    { img: "assets/images/saas1/companies/37.jpg" },
    { img: "assets/images/saas1/companies/38.jpg" },
    { img: "assets/images/saas1/companies/39.png" },
    { img: "assets/images/saas1/companies/41.png" },
    { img: "assets/images/saas1/companies/42.png" },
    { img: "assets/images/saas1/companies/43.jpg" },
    { img: "assets/images/saas1/companies/44.png" },
    { img: "assets/images/saas1/companies/46.png" },
    { img: "assets/images/saas1/companies/47.jpg" },
    { img: "assets/images/saas1/companies/49.png" },
    { img: "assets/images/saas1/companies/50.png" },
    { img: "assets/images/saas1/companies/52.png" },
    { img: "assets/images/saas1/companies/53.png" },
    { img: "assets/images/saas1/companies/54.jpg" },
    { img: "assets/images/saas1/companies/55.png" },
    { img: "assets/images/saas1/companies/56.jpg" },
    { img: "assets/images/saas1/companies/57.png" },
    { img: "assets/images/saas1/companies/58.jpg" },
    { img: "assets/images/saas1/companies/59.jpg" },
    { img: "assets/images/saas1/companies/60.jpg" },
    { img: "assets/images/saas1/companies/61.png" },
    { img: "assets/images/saas1/companies/62.png" },
    { img: "assets/images/saas1/companies/63.jpg" },
    { img: "assets/images/saas1/companies/64.jpg" },
    { img: "assets/images/saas1/companies/65.jpg" },
    { img: "assets/images/saas1/companies/66.png" },
    { img: "assets/images/saas1/companies/67.jpg" },
    { img: "assets/images/saas1/companies/75.png" },
  ]
  ngOnInit() {
    this.title.setTitle(this.route.snapshot.data['title']);
  }


}
